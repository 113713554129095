import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import DensListView from '../../components/dens-list-view/dens-list-view';
import ListView from '../../components/list-view/list-view';
import NoDataFound from '../../components/no-data-found/no-data-found';
import Toast from '../../components/toast/toast';
import { Post } from '../../libraries/db-types';
import LS, { SearchHistory } from '../../services/local-storage-service';
import DB from '../../services/storage-service';
import './search.scss';

type Props = {
  active: boolean;
  setSearchWords: (words: string) => void;
}

const Search = forwardRef<unknown, Props>((props, ref) => {

  const [words, setWords] = useState('');
  const listRef = useRef();

  useImperativeHandle(ref, () => ({
    onSearchRequest: (str: string) => {
      setWords(str);
    }
  }));

  const [items, setItems] = useState<Post[]>([]);
  const [searchLog, setSearchLog] = useState<SearchHistory[]>([]);
  const [loading, setLoading] = useState(false);
  const [noResult, setNoResult] = useState('');

  const onDeleteClick = (data: SearchHistory, index: number) => {
    setSearchLog(() => LS.removeHistory(data));
  }


  useEffect(() => {
    if (words !== '') {
      setLoading(true);
      DB.searchItems(words).then(ret => {
        setItems(ret);

        setLoading(false);

        if (ret.length) {
          setSearchLog(() => LS.addHistory(words, ret.length));
        } else {
          setNoResult(words);
          props.setSearchWords('')

          setTimeout(() => {
            setNoResult('');
          }, 2000)
        }

        listRef.current && (listRef.current as any).scrollToTop();

        
      }).catch(err => {
        setLoading(false);
      });
    } else {
      setItems([]);
      setSearchLog(LS.getHistory());
    }
  }, [words/*, props.setSearchWords これを入れると画面遷移の度に実行されてしまう*/])


  return <section id="search" className={props.active ? 'active' : 'hidden'}>
    <div className="index"></div>
    <div className='container-fluid'>
      {items.length ?
        <ListView ref={listRef} items={items}>
          <NoDataFound></NoDataFound>
        </ListView>
        :
        <DensListView
         items={searchLog}
         type={'histories'}
         onListClick={props.setSearchWords}
         onDeleteClick={onDeleteClick}>
          <NoDataFound>検索すると履歴が表示されます。</NoDataFound>
        </DensListView>
      }
    </div>
    {loading && <div className='loading' aria-busy={loading}></div>}
    {/* {noResult.length !== 0 && <div className='no-result'>
          <p>{noResult} に一致する検索結果がありません。</p>
        </div>} */}
    <Toast show={noResult.length !== 0} text={`${noResult} に一致する検索結果がありません。`}></Toast>
  </section>;
})
export default Search;