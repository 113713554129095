import { ICountQuery, IWhereQuery } from 'jsstore';
import { Author, DataType, Post, tblName, tblNameType } from '../libraries/db-types';
import { words2searchReg } from '../libraries/utilities';
import { BaseService } from './storage-base-service';

/**
 * Indexeddb との接続サービス
 */

let StorageServiceInstance: StorageService;

class StorageService extends BaseService {
  /**
   * 使い方
   * BaseService の connection()を使ってトランザクションが開始できる
   * https://jsstore.net/tutorial/database/
   */
  constructor() {
    super();
    console.log('Storage Service constraction.')
    if (StorageServiceInstance) {
      throw new Error('Storage Service can be only created one instance.');
    }
    StorageServiceInstance = this;
  };

  /**
   * データを1件更新
   * @param tableName テーブル名
   * @param id 更新対象のid
   * @param data データ1件
   * @returns 
   */
  updateData(tableName: tblNameType, id: number, data: DataType) {
    // console.log(data);
    return this.connection.update({
      in: tableName,
      set: data,
      where: {
        id: id
      }
    });
  }


  /**
   * データを1件挿入する
   * @param tableName テーブル名
   * @param data データ1件
   * @returns number | unknown[]
   */
  addData(tableName: tblNameType, data: DataType) {
    return this.connection.insert({
      into: tableName,
      values: [data],
      ignore: true
    })
  }


  /**
   * データ配列を挿入する。キーが存在していたら上書きする
   * @param tableName テーブル名
   * @param data データ1件
   * @returns number | unknown[]
   */
  addDataArray(tableName: tblNameType, dataArray: DataType[]) {
    return this.connection.insert({
      into: tableName,
      upsert: true,
      values: dataArray,
      ignore: true
    })
  }


  /**
   * テーブル名とIDを指定して削除する
   * @returns 削除したデータのid
   */
  deleteData(tableName: tblNameType, id: number) {
    return this.connection.remove({
      from: tableName,
      where: {
        id: id,
      }
    })
  }

  /**
   * テーブルの全レコードを削除
   * @param tableName 
   */
  deleteTable(tableName: tblNameType) {
    return this.connection.clear(tableName);
  }

  /**
   * 全てのテーブルの全レコードを削除
   * @param tableName 
   */
   deleteAllTables() {
     return Promise.all(
     Object.values(tblName).map((name) => {
      return  this.connection.clear(name);
     }));
  }


  /**
   * DB を削除
   */
  dropDB() {
    return this.connection.dropDb();
  }

  isExist() {
    return this.connection.select<Post>({
      from: tblName.Posts
    }).then(ret => !!ret.length)
  }


  getAllPosts() {
    return this.connection.select<Post>({
      from: tblName.Posts,
      order: {
        by: 'date',
        type: 'desc'
      }
    })
  }

  getPost(id: number) {
    return this.connection.select<Post>({
      from: tblName.Posts,
      where: {
        id: id
      }
    }).then(ret => ret[0]);
  }

  getAllAuthors() {
    return this.connection.select<Author>({
      from: tblName.Authors
    });
  }

  getAuthorByName(name: string) {
    return this.connection.select<Author>({
      from: tblName.Authors,
      where: {
        name: name
      }
    }).then(ret => ret[0]);
  }

  getPostsByAuthor(name: string) {
    return this.connection.select<Post>({
      from: tblName.Posts,
      order: {
        by: 'date',
        type: 'asc'
      },
      where: {
        author: name
      }
    })/*.then(ret => {
      return [...ret].sort((a, b) => b.date.getTime() - a.date.getTime());
    }).catch(err => {
      return [] as Post[];
    });*/
  }


  /**
   * マージした検索結果を日付順にして返す
   * @param word 検索ワード
   * @returns 
   */
   searchItems(words: string) {
    const reg = words2searchReg(words);
    // union で検索結果をマージして返す
    return this.connection.union<Post[]>([
      {
        // 本文から検索
        from: tblName.Posts,
        where: {
          content: {
            regex: reg
          }
        }
      },
      {
        // タイトルから検索
        from: tblName.Posts,
        where: {
          title: {
            regex: reg
          },
        }
      },
      {
        // 投稿者から検索
        from: tblName.Posts,
        where: {
          author: {
            regex: reg
          },
        }
      }
    ]).then(ret => {
      return [...ret].sort((a, b) => b.date.getTime() - a.date.getTime());
    }).catch(err => {
      return [] as Post[];
    })
  }

  /**
   * データ件数取得
   * @param tableName 
   * @param where 
   * @returns Promise
   */
   getDataCount(tableName: tblNameType, where?: IWhereQuery | IWhereQuery[]) {
    const query: ICountQuery = {
      from: tableName
    }
    if (where) {
      query.where = where;
    }
    return this.connection.count(query);
  }
  
  /**
   * お気に入りを全件取得
   * @returns 全記事データ
   */
   getFavPostItems(option?: {
    order?: {by: string, type: 'asc' | 'desc'},
    where?: IWhereQuery
  }) {
   return this.connection.select<Post>({
     from: tblName.Posts,
     order: option?.order || {
       by: 'date',
       type: 'desc'
       },
     where: option?.where || {
       fav: {
        '!=': 0
       },
     }
   })
 }

  // getStudents() {
  //   return this.connection.select<Student>({
  //     from: 'Students'
  //   });
  // }

  // addStudent(student: Student) {
  //   return this.connection.insert({
  //     into: 'Students',
  //     values: [student]
  //   });
  // }

  // deleteStudent(studentId: number) {
  //   return this.connection.remove({
  //     from: 'Students',
  //     where: {
  //       id: studentId
  //     }
  //   });
  // }

  // getStudent(studentId: number) {
  //   return this.connection.select<Student>({
  //     from: 'Students',
  //     where: {
  //       id: studentId
  //     }
  //   });
  // }

  // updateStudent(studentId: number, value: any) {
  //   return this.connection.update({
  //     in: 'Students',
  //     set: value,
  //     where: {
  //       id: studentId
  //     }
  //   });
  // }
}

const DB = Object.freeze(new StorageService());
export default DB;