import React, { CSSProperties, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { virtualize } from "react-swipeable-views-utils";
import { LocationState } from "../../components/page-stack";
import SingleView from "../../components/single-view/single-view";
import TopAppBar from "../../components/top-app-bar/top-app-bar";
import { tblName } from "../../libraries/db-types";
import DB from "../../services/storage-service";
import './single.scss';

type Props = {
  photo?: boolean,
  index?: number,
  data: LocationState;
  onBackButtonClick: () => void;
}

type SlideParams = {
  index: number,
  key: number,
}

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const Single: React.FC<Props> = (props) => {

  /**
   * Slider view を実装する場合ここで表示するデータを管理する
   * SingleViewに渡すのはitemsではなくitemになる
   */
  const [index, setIndex] = useState(props.index || 0);
  const [doBack, setDoBack] = useState(false);

  const navigate = useNavigate();

  const onBackButtonClick = () => {
    props.onBackButtonClick();
  }

  const swipeBack = (state: boolean) => {
    setDoBack(state);
  }

  const handleChangeIndex = (idx: number) => {
    setIndex(pre => {
      return idx
    });
    const updated = props.data.items[idx];
    updated.view += 1;
    DB.updateData(tblName.Posts, updated.id, updated)
  }

  const slideRenderer = (params: SlideParams) => {
    const { index, key } = params;
    const style = {
      slide: {
        margin: 0,
        padding: 0,
        WebkitOverflowScrolling: 'touch',
      } as CSSProperties
    };


    return <article style={Object.assign({}, style.slide)} key={key}>
      <SingleView item={props.data.items[index]} swipeBack={swipeBack}></SingleView>
    </article>
  }

  useEffect(() => {
    if (props.data == null) {
      navigate('/');
    }
  }, [navigate, props.data]);




  return <div className="single">
    {props.data ? <>
      <TopAppBar
        backIcon
        onBackButtonClick={onBackButtonClick}
        title={props.data.items[index].title}></TopAppBar>
      {/* <SingleView item={props.data.items[0]}></SingleView> */}
      <VirtualizeSwipeableViews
        slideCount={props.data.items.length}
        containerStyle={{}}
        index={index}
        onChangeIndex={handleChangeIndex}
        slideRenderer={slideRenderer}
        disabled={doBack}
      ></VirtualizeSwipeableViews>
    </>
      : null}
  </div>
}
export default Single;