import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ListView from "../../components/list-view/list-view";
import NoDataFound from "../../components/no-data-found/no-data-found";
import { LocationState } from "../../components/page-stack";
import TopAppBar from "../../components/top-app-bar/top-app-bar";
import './list.scss';

type Props = {
    data: LocationState
    onBackButtonClick: () => void;
}

const List: React.FC<Props> = (props) => {
  
  const navigate = useNavigate();

  const onBackButtonClick = () => {
    props.onBackButtonClick();
  }
  
  useEffect(() => {
    if (props.data == null) {
      navigate('/');
    }
  }, [props.data, navigate]);

  return <div className="list">
    {props.data ? <>
      <TopAppBar backIcon onBackButtonClick={onBackButtonClick} title={props.data.title}></TopAppBar>
      <div className="container-fluid">
      <ListView items={props.data.items.slice()}><NoDataFound></NoDataFound></ListView>
      </div>
    </>
    : null}
    {/* {<>
    {state.items.map((v,i) => <div key={i}>{v.id}</div>)}
    </>} */}
  </div>
}
export default List;