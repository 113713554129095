import { useEffect, useState } from 'react';
import ListView from '../../components/list-view/list-view';
import NoDataFound from '../../components/no-data-found/no-data-found';
import { Post } from '../../libraries/db-types';
import { myEnv } from '../../libraries/_env';
import DB from '../../services/storage-service';
import './favorites.scss';

type Props = {
  active: boolean;
  updateSwitch: boolean;
  onTop: boolean;
}

const Favorites: React.FC<Props> = (props) => {

  const [items, setItems] = useState<Post[]>([]);

  useEffect(() => {
    DB.getFavPostItems().then(ret => {
      setItems(ret);
    });
  }, [props.updateSwitch, props.active, props.onTop])

  return <section id="favorites" className={props.active ? 'active' : 'hidden'}>
    <div className="index"></div>
    <div className='container-fluid'>
      <ListView items={items}><NoDataFound>{myEnv.strings.noFavPosts}</NoDataFound></ListView>
    </div>
  </section>;
}
export default Favorites;