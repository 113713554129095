import { myEnv } from "./_env";

/**
 * 最上位タグの子要素になるようにセレクタを合成
 * @param name 
 * @returns string
 */
export const topTagSelector = (name: string) => {
  return `${myEnv.topTagName} > ${name}`;
}


export const replaceImgUrl = (data: string) => {
  return data.replace(
    new RegExp(`(http.+)?(/?${myEnv.imgUploadDir.from}`, 'gm'),
    myEnv.imgUploadDir.to);
}

/**
 * <![CDATA[]]>の削除
 * @param data 文字列
 * @returns 文字列
 */
export const cdata2string = (data: string) => {
  return data.replace(/<!\[CDATA\[(.*?)\]\]>/g, `$1`);
}

/**
 * 複数行の
 * ・データの先頭<![CDATA[と文末]]>を削除
 * ・コメントを削除
 * ・改行コードをbrに置換する
 * @param data 文字列
 * @returns 文字列
 */
export const cdata2multiString = (data: string) => {
  const str = replaceImgUrl(
    data
      .replace(/^<!\[CDATA\[/gm, ``)  // 先頭のCDATAを削除
      .replace(/\]\]>$/gm, '')  // 末尾のとじを削除
      .replace(/\r|\n/gm, '\n')
      .replace(/<!--[\s\S]*?-->/gm, '') // コメントを削除
      .replace(/(\r\n){3,}|\r{3,}|\n{3,}/gm, '\n') // 連続した改行を制限
      .replace(/\n/gm, '<br/>') // 改行をタグに変換
      .replace(/^(<br\/?>)+/gm, '').replace(/(<br\/?>)+$/gm, '') // 先頭と最後の改行を削除
      .replace(/<\/p><br\/?>/gm, '</p>') // p の直後の改行を削除
  );

  // img を div.image-box でラップする
  const parsed = new DOMParser().parseFromString(str, 'text/html');
  [].slice.call(parsed.querySelectorAll('img')).forEach((imgEl: HTMLImageElement, index) => {
    // console.log(imgEl);
    const divEl = document.createElement('div');
    divEl.className = 'image-box';
    imgEl.parentNode?.insertBefore(divEl, imgEl);
    divEl.appendChild(imgEl);
  });
  return parsed.body.innerHTML;
}

export const cdata2imgUrl = (data: string) => {
  return data.replace(/<!\[CDATA\[(.*?)\]\]>/g, `$1`);
}


/**
 * 日付文字列をDateへ変換
 * @param dateString 日付文字列
 * @returns Date オブジェクト
 */
export const toDate = (dateString: string) => {
  // Safari では日付文字列にハイフンがあるとエラーになる！
  return new Date(dateString.replace(/-/g, '/'));
}


export const generateExcerpt = (content: string) => {
  // htmlタグと改行を削除、スペースも削除
  const textContent = content
    .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')
    .replace(/&nbsp;/g, '')
    .replace(/\r?\n/g, '');

  return `${textContent
    // 設定の文字数分切り出し
    .substring(0, myEnv.excerptLength)}${
    // 設定文字数以上のコンテンツには3点リーダーをつける
    textContent.length > myEnv.excerptLength ? '...' : ''}`;
}

/**
 * 検索ワードを正規表現に変換（今のところorのみ）
 * @param words 検索ワード
 * @returns 正規表現
 */
export const words2searchReg = (words: string, type: 'and' | 'or' = 'and') => {

  // 前後の空白を削除してスペースで分割
  const str = words.trim().split(/\s/);
  if (str.length < 2) {
    // 1語の検索の場合
    return new RegExp(str.join(''), 'gim');
  } else {
    // 複数ワード検索の場合
    if (type === 'and') {
      // and 検索
      // console.log(`^(?=.*${str.join(`)(?=.*`)}).*$`);
      return new RegExp(`^(?=.*${str.join(`)(?=.*`)}).*$`, 'gim');
    } else {
      // or 検索
      return new RegExp(str.join('|'), 'gim');
    }
  }

  // or 検索
  // スペースを|に置き換えて正規表現へ変換
  // return new RegExp(words.replace(/\s/gi, '|'), 'gim');
}

// /**
//  * 投稿データから抜粋データを作成
//  * @param item Item
//  * @returns Excerpt
//  */
// export const genPostExcerpt = (item: Item) => {
//   return {
//     post_id: item.post_id,
//     title: item.title,
//     excerpt: item.excerpt,
//     category: item.category,
//     post_tag: item.post_tag,
//     post_date: item.post_date,
//     post_fav: item.post_fav,
//   } as Excerpt;
// }

/**
 * number を
 * @param value 
 * @param dec 
 * @returns 
 */
export const countFormat = (value: number, dec: number = 2) => {
  // 2 decimal places => 100, 3 => 1000, etc
  let ret = `${value}`;
  const decPlaces = Math.pow(10, dec);

  // Enumerate number abbreviations
  var abbrev = ["k", "m", "b", "t"];

  // Go through the array backwards, so we do the largest first
  for (var i = abbrev.length - 1; i >= 0; i--) {

    // Convert array index to "1000", "1000000", etc
    var size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= value) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      ret = `${Math.round(value * decPlaces / size) / decPlaces}${abbrev[i]}`;

      // We are done... stop
      break;
    }
  }

  return ret;
}



const convertKana = (str: string) => {
  const kanaMap = {
    'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
    'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
    'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
    'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
    'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
    'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
    'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
    'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
    'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
    'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
    'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
    'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
    'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
    'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
    'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
    'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
    'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
    'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
    '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
  };
  const reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
  return str
    .replace(reg, function (match) {
      return (kanaMap as any)[match];
    })
    .replace(/ﾞ/g, '゛')
    .replace(/ﾟ/g, '゜');
}

export const convertWidth = (str: string) => {
  return convertKana(str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  }).trim())
}