import { Author, ImportDataRow, Post } from "./db-types";
import { convertWidth, generateExcerpt, toDate } from "./utilities";



// export const getString = (dom: Element | null, name: string) => { return cdata2string(dom?.querySelector(name)?.innerHTML || '') };
// export const getNumber = (dom: Element | null, name: string) => { return parseInt(dom?.querySelector(name)?.innerHTML || '0') }

// export const parseGeneralData = (doc: Document) => {
//   const data: General = {
//     title: cdata2string(doc.querySelector(topTagSelector('title'))?.innerHTML || ''),
//     link: cdata2string(doc.querySelector(topTagSelector('link'))?.innerHTML || ''),
//     description: cdata2string(doc.querySelector(topTagSelector('description'))?.innerHTML || ''),
//     pubDate: toDate(cdata2string(doc.querySelector(topTagSelector('pubDate'))?.innerHTML || '')),
//     language: cdata2string(doc.querySelector(topTagSelector('language'))?.innerHTML || ''),
//     wxr_version: parseFloat(doc.querySelector(topTagSelector('wxr_version'))?.innerHTML || '0'),
//     base_site_url: cdata2string(doc.querySelector(topTagSelector('base_site_url'))?.innerHTML || ''),
//     base_blog_url: cdata2string(doc.querySelector(topTagSelector('base_blog_url'))?.innerHTML || ''),
//     generator: cdata2string(doc.querySelector(topTagSelector('generator'))?.innerHTML || ''),
//     image: {
//       url: cdata2string(doc.querySelector(topTagSelector('image > url'))?.innerHTML || ''),
//       title: cdata2string(doc.querySelector(topTagSelector('image > title'))?.innerHTML || ''),
//       link: cdata2string(doc.querySelector(topTagSelector('image > link'))?.innerHTML || ''),
//       width: parseInt(doc.querySelector(topTagSelector('image > width'))?.innerHTML || '0'),
//       height: parseInt(doc.querySelector(topTagSelector('image > height'))?.innerHTML || '0'),
//     }
//   };

//   return data;
// }

// export const parseAuthor = async (dom: Element) => {
//   const data: Author = {
//     author_id: getNumber(dom, 'author_id'),
//     author_login: getString(dom, 'author_login'),
//     author_email: getString(dom, 'author_email'),
//     author_display_name: getString(dom, 'author_display_name'),
//     author_first_name: getString(dom, 'author_first_name'),
//     author_last_name: getString(dom, 'author_last_name')
//   }
//   return data;
// }


// export const parseCategory = async (dom: Element) => {
//   const data: Category = {
//     term_id: getNumber(dom, 'term_id'),
//     category_nicename: getString(dom, 'category_nicename'),
//     category_parent: getString(dom, 'category_parent'),
//     cat_name: getString(dom, 'cat_name')
//   }
//   return data;
// }

// export const parseTag = async (dom: Element) => {
//   const data: Tag = {
//     term_id: getNumber(dom, 'term_id'),
//     tag_slug: getString(dom, 'tag_slug'),
//     tag_name: getString(dom, 'tag_name')
//   }
//   return data;
// }

// export const parseTerm = async (dom: Element) => {
//   const data: Term = {
//     term_id: getNumber(dom, 'term_id'),
//     term_taxonomy: getString(dom, 'term_taxonomy'),
//     term_slug: getString(dom, 'term_slug'),
//     term_parent: getString(dom, 'term_parent'),
//     term_name: getString(dom, 'term_name'),
//     // additional
//     term_view: 0,
//     term_count: 0,
//   }
//   const exist = await DB.getTerm(getNumber(dom, 'term_id'));
//   if (exist) {
//     data.term_view = exist.term_view;
//     // term_count はデータ取り込み中に計算して上書きされる。
//   }
//   return data;
// }

// export const parseItem = async (dom: Element) => {
//   const data: Item = {
//     title: getString(dom, 'title'),
//     link: getString(dom, 'link'),
//     pubDate: toDate(getString(dom, 'pubDate')),
//     creator: getString(dom, 'creator'),
//     guid: getString(dom, 'guid'),
//     description: getString(dom, 'description'),
//     content: cdata2multiString(getString(dom, 'content')),
//     excerpt: getString(dom, 'excerpt'),
//     post_id: getNumber(dom, 'post_id'),
//     post_date: toDate(getString(dom, 'post_date')),
//     post_date_gmt: toDate(getString(dom, 'post_date_gmt')),
//     post_modified: toDate(getString(dom, 'post_modified')),
//     post_modified_gmt: toDate(getString(dom, 'post_modified_gmt')),
//     comment_status: getString(dom, 'comment_status'),
//     ping_status: getString(dom, 'ping_status'),
//     post_name: getString(dom, 'post_name'),
//     status: getString(dom, 'status'),
//     post_parent: getNumber(dom, 'post_parent'),
//     menu_order: getNumber(dom, 'menu_order'),
//     post_type: getString(dom, 'post_type'),
//     post_password: getString(dom, 'post_password'),
//     is_sticky: getNumber(dom, 'is_sticky'),
//     // attachment は無視する
//     // attachment_url?: getString(dom, 'attachment_url'),

//     // category を分割して登録する
//     category: [].slice.call(dom.querySelectorAll('category[domain="category"')).map((value: Element) => { return cdata2string(value.innerHTML || '') }),
//     post_tag: [].slice.call(dom.querySelectorAll('category[domain="post_tag"')).map((value: Element) => { return cdata2string(value.innerHTML || '') }),
//     postmeta: dom.querySelectorAll('postmeta')
//       ? [].slice.call(dom.querySelectorAll('postmeta')).map((value: Element) => {
//         return {
//           meta_key: getString(value, 'meta_key'),
//           meta_value: getString(value, 'meta_value'),
//         }
//       }) : [],
//     // additional
//     post_fav: false,
//     post_view: 0
//   }

//   // 抜粋が無ければ合成する
//   if (data.excerpt === "") {
//     data.excerpt = generateExcerpt(data.content);
//   }

//   // アプリ側で保持するデータ
//   const exist = await DB.getPost(data.post_id);
//   if (exist) {
//     data.post_fav = exist.post_fav;
//     data.post_view = exist.post_view;
//   }

//   return data;
// }


// export const parseAttachment = async (dom: Element) => {
//   const data: Media = {
//     title: getString(dom, 'title'),
//     link: getString(dom, 'link'),
//     pubDate: toDate(getString(dom, 'pubDate')),
//     creator: getString(dom, 'creator'),
//     guid: getString(dom, 'guid'),
//     description: getString(dom, 'description'),
//     // content: cdata2multiString(getString(dom, 'content')),
//     // excerpt: getString(dom, 'excerpt'),
//     post_id: getNumber(dom, 'post_id'),
//     post_date: toDate(getString(dom, 'post_date')),
//     post_date_gmt: toDate(getString(dom, 'post_date_gmt')),
//     post_modified: toDate(getString(dom, 'post_modified')),
//     post_modified_gmt: toDate(getString(dom, 'post_modified_gmt')),
//     comment_status: getString(dom, 'comment_status'),
//     ping_status: getString(dom, 'ping_status'),
//     post_name: getString(dom, 'post_name'),
//     status: getString(dom, 'status'),
//     post_parent: getNumber(dom, 'post_parent'),
//     menu_order: getNumber(dom, 'menu_order'),
//     post_type: getString(dom, 'post_type'),
//     post_password: getString(dom, 'post_password'),
//     is_sticky: getNumber(dom, 'is_sticky'),
//     // attachment は無視する
//     attachment_url: replaceImgUrl(getString(dom, 'attachment_url')),

//     // category を分割して登録する
//     // category: [].slice.call(dom.querySelectorAll('category[domain="category"')).map((value: Element) => { return cdata2string(value.innerHTML || '') }),
//     // post_tag: [].slice.call(dom.querySelectorAll('category[domain="post_tag"')).map((value: Element) => { return cdata2string(value.innerHTML || '') }),
//     postmeta: dom.querySelectorAll('postmeta')
//       ? [].slice.call(dom.querySelectorAll('postmeta')).map((value: Element) => {
//         return {
//           meta_key: getString(value, 'meta_key'),
//           meta_value: getString(value, 'meta_value'),
//         }
//       }) : [],
//     // additional
//     post_fav: false,
//     post_view: 0
//   }

//   // キャッシュのために画像を fetch する
//   // if (data.attachment_url !== "") {
//   //   const url = document.createElement('a');
//   //   url.href = data.attachment_url;
//   //   // console.log(
//   //   //   data,
//   //     fetch(url.href, {
//   //       mode: 'cors'
//   //     });
//   //   // )
//   // }

//   // アプリ側で保持するデータ
//   const exist = await DB.getMedia(data.post_id);
//   if (exist) {
//     data.post_fav = exist.post_fav;
//     data.post_view = exist.post_view;
//   }

//   return data.attachment_url !== "" ? data : null;
// }


export const dataParser = (data: ImportDataRow, index: number) => {
  const post: Post = {
    author: convertWidth(data.author),
    title: convertWidth(data.title),
    content: convertWidth(data.content),
    excerpt: generateExcerpt(convertWidth(data.content)),
    date: toDate(data.datetime),
    id: index,
    fav: 0,
    view: 0,
  }
  const author: Author = {
    id: 0,
    name: convertWidth(data.author),
    count: 0,
    view: 0
  }
  return {post: post, author: author};
}

export const setAuthorId = (data: Author[]) => {
  return data.map((val, idx) => {
    val.id = idx;
    return val;
  })
}

// /**
//  * テスト用未使用
//  * @param doc 
//  * @returns 
//  */
// export const parseXml = (doc: Document) => {
//   const dom = doc.querySelector(myEnv.topTagName);

//   // channel が無かったらファイルが違うので終わり
//   if (dom == null) { return; }

//   // 処理数
//   const length = dom.children.length;
//   let count = 0;
//   let excludeCnt = 0;

//   const generalData = parseGeneralData(doc);  // length 10
//   count = 10;

//   // Author
//   const authorData: Author[]  = []
//   // count++;

//   const categoryData: Category[] = [];
//   const tagData: Tag[] = [];
//   const termData: Term[] = [];
//   const itemData: Item[] = [];


//   doc.querySelectorAll(topTagSelector('author')).forEach((value) => {
//     authorData.push(parseAuthor(value));
//     count++;
//   });

//   doc.querySelectorAll(topTagSelector('category')).forEach((value) => {
//     categoryData.push(parseCategory(value));
//     count++;
//   });

//   doc.querySelectorAll(topTagSelector('tag')).forEach((value) => {
//     tagData.push(parseTag(value));
//     count++;
//   });

//   doc.querySelectorAll(topTagSelector('term')).forEach((value) => {
//     termData.push(parseTerm(value));
//     count++;
//   });

//   doc.querySelectorAll(topTagSelector('item')).forEach((value) => {
//     // attachmentを除外する
//     if (getString(value, 'post_type') === 'attachment') {
//       excludeCnt++;
//     } else {
//       itemData.push(parseItem(value));
//     }
//     count++;
//   });

// }