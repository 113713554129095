import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { IconName } from "../components/icons/icons";
import NavigationBar from "../components/navigation-bar/navigation-bar";
import TopAppBar from "../components/top-app-bar/top-app-bar";
import { myEnv } from "../libraries/_env";
import LS from "../services/local-storage-service";
import Favorites from "../templates/favorites/favorites";
import Home from "../templates/home/home";
import Search from "../templates/search/search";
import Terms from "../templates/terms/terms";

type Props = {
}

const Content: React.FC<Props> = (props) => {



  const [activeMenu, setActiveMenu] = useState<IconName>('home');
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [onTop, setOnTop] = useState(true);
  const appBarRef = useRef();
  const searchRef = useRef();
  const termsRef = useRef();
  const location = useLocation();

  const onActiveChange = (name: IconName) => {
    if (activeMenu === 'search' && name === 'search') {
      appBarRef.current && (appBarRef.current as any).focusRequest();
    }
    setActiveMenu(name);
  }

  const onUpdateRequest = () => {
    setActiveMenu('home');

    setUpdateSwitch(pre => !pre);

  }

  const onSearchRequest = (words: string) => {
    searchRef.current && (searchRef.current as any).onSearchRequest(words);
  }

  const setSearchWords = (words: string) => {
    appBarRef.current && (appBarRef.current as any).setSearchWords(words);
  }

  const onFilterChange = (tagFilter: string) => {
    termsRef.current && (termsRef.current as any).setFilterWords(tagFilter);
  }

  const onSelectChange = (sortType: string) => {
    termsRef.current && (termsRef.current as any).setSortType(sortType);
  }


  useEffect(() => {
    if (location.pathname === '/') {
      setOnTop(pre => !pre);
    }
  }, [location])


  return <div className="contents">
    <TopAppBar
      ref={appBarRef}
      title={`${LS.getSiteName() ? LS.getSiteName() : myEnv.title.home}`}
      active={activeMenu}
      onSearchRequest={onSearchRequest}
      settingIcon={activeMenu === 'home' || activeMenu === 'article'}
      onSettingEnd={onUpdateRequest}
      onFilterChange={onFilterChange}
      onSelectChange={onSelectChange}></TopAppBar>
    <main>
      <Home
        active={activeMenu === 'home'}
        onTop={onTop}
        updateSwitch={updateSwitch}></Home>
      {/* <Articles active={activeMenu === 'article'} updateSwitch={updateSwitch}></Articles> */}
      
      {/* <Gallery
        active={activeMenu === 'photos'}
        updateSwitch={updateSwitch}></Gallery> */}
      
      <Search ref={searchRef}
        active={activeMenu === 'search'}
        setSearchWords={setSearchWords} /*updateSwitch={updateSwitch}*/></Search>
      
      <Terms ref={termsRef}
        active={activeMenu === 'bookmarks'}
        updateSwitch={updateSwitch}></Terms>
      
      <Favorites
        active={activeMenu === 'favorite'}
        onTop={onTop}
        updateSwitch={updateSwitch}></Favorites>
      {/* <SingleDialog appEl={props.appEl} items={items} index={sIndex} toggle={toggleSingleView} show={singleViewState}></SingleDialog> */}
    </main>
    <NavigationBar active={activeMenu} onActiveChange={onActiveChange}></NavigationBar>

  </div>
}
export default Content;