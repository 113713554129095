import { useCallback, useEffect, useState } from 'react';
import Main from './layouts/main';
import { myEnv } from './libraries/_env';
import LS from './services/local-storage-service';
import { initJsStore } from './services/storage-base-service';
import DB from './services/storage-service';
import './theme/style.scss';


initJsStore();

function App() {

  const [loaded, setLoaded] = useState(false);


  const updateData = useCallback(() => {
    // サイト基本情報の取得
    DB.isExist().then(ret => {
      if (!ret) {
        alert(`${myEnv.strings.noSiteData}\n${myEnv.strings.noDataFound}`);
      }
    }).catch(err => {
      alert(`${myEnv.strings.dbConnectionError}\n${JSON.stringify(err)}`);
    }).then(() => {
      setTimeout(() => {
        setLoaded(true);
      }, 2200)
    });
  }, [])

  useEffect(() => {
    // console.log(appData);
    // サイト基本情報の取得
    updateData();
  }, [updateData]);
  return (
    <>
    <div className="App">
      <Main></Main>
    </div>
    {!loaded && <div className="splash">
        {/* <Icon name='bio' onClick={() => {console.log('Now Loading')}}></Icon> */}
        {/* <div aria-busy></div> */}
        <div><h1>{LS.getSiteName() || myEnv.title.home }</h1></div>
      </div>}
    </>
  );
}

export default App;
