export const tblName = {
  Posts: 'Posts',
  Authors: 'Authors'
} as const;
export type tblNameType = keyof typeof tblName;

export type DataType = Post | Author;

export type ImportDataRow = {
  author: string,
  title: string,
  content: string,
  datetime: string,
}

export type Post = {
  id: number,
  author: string,
  title: string,
  excerpt: string,
  content: string,
  date: Date,
  fav: number,
  view: number
}

export type Author = {
  id: number,
  name: string,
  count: number,
  view: number,
}