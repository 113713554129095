import React from "react";
import { ReactComponent as ArrowBack } from "./svg/arrow_back.svg";
import { ReactComponent as Article } from "./svg/article.svg";
import { ReactComponent as ArticleActive } from "./svg/article_active.svg";
import { ReactComponent as Bookmarks } from "./svg/bookmarks.svg";
import { ReactComponent as BookmarksActive } from "./svg/bookmarks_active.svg";
import { ReactComponent as Favorite } from "./svg/favorite.svg";
import { ReactComponent as FavoriteActive } from "./svg/favorite_active.svg";
import { ReactComponent as Home } from "./svg/home.svg";
import { ReactComponent as HomeActive } from "./svg/home_active.svg";
import { ReactComponent as Photos } from "./svg/photos.svg";
import { ReactComponent as PhotosActive } from "./svg/photos_active.svg";
import { ReactComponent as Search } from "./svg/search.svg";
import { ReactComponent as SearchActive } from "./svg/search_active.svg";
import { ReactComponent as Settings } from "./svg/settings.svg";
import { ReactComponent as Views } from "./svg/view.svg";

export type IconName = 'arrow' | 'article' | 'bookmarks' | 'favorite' | 'home' | 'search' | 'settings' | 'photos' | 'views';

type Props = {
  name: IconName;
  active?: boolean;
  onClick: (name: IconName, event?: React.MouseEvent<SVGElement, MouseEvent>) => void
}


const Icon: React.FC<Props> = (props) => {

  const onClickHandler = (name: IconName, event: React.MouseEvent<SVGElement, MouseEvent>) => {
    props.onClick(name, event);
  }

  if (props.active === true) {
    switch (props.name) {
      case 'arrow':
        return <ArrowBack onClick={(event) => onClickHandler(props.name, event)}></ArrowBack>;

      case 'article':
        return <ArticleActive onClick={(event) => onClickHandler(props.name, event)}></ArticleActive>;

      case 'bookmarks':
        return <BookmarksActive onClick={(event) => onClickHandler(props.name, event)}></BookmarksActive>;

      case 'favorite':
        return <FavoriteActive onClick={(event) => onClickHandler(props.name, event)}></FavoriteActive>;

      case 'home':
        return <HomeActive onClick={(event) => onClickHandler(props.name, event)}></HomeActive>;

      case 'search':
        return <SearchActive onClick={(event) => onClickHandler(props.name, event)}></SearchActive>;

      case 'settings':
        return <Settings onClick={(event) => onClickHandler(props.name, event)}></Settings>;

      case 'photos':
        return <PhotosActive onClick={(event) => onClickHandler(props.name, event)}></PhotosActive>

      case 'views':
        return <Views onClick={(event) => onClickHandler(props.name, event)}></Views>

      default:
        return <HomeActive onClick={(event) => onClickHandler(props.name, event)}></HomeActive>;
    }
  } else {
    switch (props.name) {
      case 'arrow':
        return <ArrowBack onClick={(event) => onClickHandler(props.name, event)}></ArrowBack>;

      case 'article':
        return <Article onClick={(event) => onClickHandler(props.name, event)}></Article>;

      case 'bookmarks':
        return <Bookmarks onClick={(event) => onClickHandler(props.name, event)}></Bookmarks>;

      case 'favorite':
        return <Favorite onClick={(event) => onClickHandler(props.name, event)}></Favorite>;

      case 'home':
        return <Home onClick={(event) => onClickHandler(props.name, event)}></Home>;

      case 'search':
        return <Search onClick={(event) => onClickHandler(props.name, event)}></Search>;

      case 'settings':
        return <Settings onClick={(event) => onClickHandler(props.name, event)}></Settings>;

      case 'photos':
        return <Photos onClick={(event) => onClickHandler(props.name, event)}></Photos>

      case 'views':
        return <Views onClick={(event) => onClickHandler(props.name, event)}></Views>
        
      default:
        return <Home onClick={(event) => onClickHandler(props.name, event)}></Home>;
    }
  }
}
export default Icon;