import { CSSProperties } from 'react';
import { Post } from '../../libraries/db-types';
import DateFormat from '../date-format/date-format';
import Icon from '../icons/icons';
import './card.scss';

type Props = {
  index: number;
  style: CSSProperties;
  item: Post,
  onClickHandler: (index: number) => void;
}

const Card: any = ({
    index,
    style,
    item,
    onClickHandler
  } : Props) => {




  return <>
  <article className={'item'} style={style} onClick={() => onClickHandler(index)}>
    <div>
      <header><h4>{item.title}</h4></header>
      <div className='excerpt'>
        <p>{item.excerpt}</p>
      </div>
      <footer>
        <span>
          <i className='icon-date'></i><small>
            <DateFormat date={item.date}></DateFormat>
          </small>
        </span>
        {/* <span>
          <i className='icon-label'></i><small>
            {item.post_tag.length}
          </small>
        </span> */}
        <span>
          <i><Icon name='views' onClick={() => {}}></Icon></i><small>
            {item.view}
          </small>
        </span>
        <span>
          <small>
            <Icon name='favorite' onClick={() => {}} active={!!item.fav}></Icon>
          </small>
        </span>
        {/* <Spacer></Spacer> */}
      </footer>
    </div>
  </article>
  {/* <article className={'item'} style={style} onClick={() => onClickHandler(index)}>
    <div>
      <header><h4>{item.title}</h4></header>
      <div className='excerpt'>
        <p>{item.excerpt}</p>
      </div>
      <footer>
        <DateFormat date={item.pubDate}></DateFormat>
      </footer>
    </div>
  </article> */}
  {/* <SingleDialog item={item} toggle={toggleSingleView} show={showSingle}></SingleDialog> */}
  </>
}
export default Card;