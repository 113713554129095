import { Connection, DATA_TYPE, IDataBase, ITable } from 'jsstore';

// 読み込むWorkerファイルのパス
const getWorkerPath = () => {
  if (process.env.NODE_ENV === 'development') {
    //     // return require("file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.js");
    return 'jsstore.worker.js'
  }
  else {
    // return require("file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.min.js");
    return 'jsstore.worker.min.js'
  }
};

// This will ensure that we are using only one instance. 
// Otherwise due to multiple instance multiple worker will be created.
const workerPath = `${process.env.PUBLIC_URL}/assets/js/jsstore/${getWorkerPath()}`;

// indexeddb に接続
export const idbCon = new Connection(new Worker(workerPath));

// DB名
export const dbname = 'AppData';

// DB取得
const getDatabase = () => {
  // テーブル（オブジェクトストア）の定義
  /**
   * データ
   */
  const tblPosts: ITable = {
    name: 'Posts',
    columns: {
      id: { dataType: DATA_TYPE.Number, primaryKey: true },
      author: { dataType: DATA_TYPE.String },
      title: { dataType: DATA_TYPE.String },
      excerpt: { dataType: DATA_TYPE.String },
      content: { dataType: DATA_TYPE.String },
      date: { dataType: DATA_TYPE.DateTime },
      fav: { dataType: DATA_TYPE.Number },
      view: { dataType: DATA_TYPE.Number }
    }
  }

  const tableAuthors: ITable = {
    name: 'Authors',
    columns: {
      id: { dataType: DATA_TYPE.Number, primaryKey: true },
      name: { dataType: DATA_TYPE.String },
      count: { dataType: DATA_TYPE.Number },
      view: { dataType: DATA_TYPE.Number },
    }
  }

  // DBを作成
  const dataBase: IDataBase = {
    name: dbname,
    tables: [
      tblPosts,
      tableAuthors
    ]
  };
  return dataBase;
};

// 初期化用関数
export const initJsStore = () => {
  // DB作成を試行
  try {
    const dataBase = getDatabase();
    idbCon.initDb(dataBase);
  }
  catch (ex) {
    console.error(ex);
  }
};

export class BaseService {

  get connection() {
    return idbCon;
  }

}