import { format } from "date-fns";

type Props = {
  date: Date;
  format?: string
}

const DateFormat: React.FC<Props> = (props) => {
  return <time dateTime={props.date.toISOString()}>{format(props.date, props.format || 'yyyy/MM/dd')}</time>
}
export default DateFormat;