import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { dataParser, setAuthorId } from "../../libraries/convert";
import { Author, ImportDataRow, Post, tblName } from "../../libraries/db-types";
import { myEnv } from "../../libraries/_env";
import LS, { Settings } from "../../services/local-storage-service";
import DB from "../../services/storage-service";
import './settings-dialog.scss';

type Props = {
  show: boolean
  hideDialog: () => void
}

const SettingsDialog: React.FC<Props> = (props) => {

  const navigate = useNavigate();
  const [appEl, setAppEl] = useState<HTMLElement | HTMLElement[] | HTMLCollection | NodeList | undefined>(undefined);
  const [dataLoadOpen, setDataLoadOpen] = useState(false);
  const [siteInfo, setSiteInfo] = useState({
    Authors: 0,
    Posts: 0,
  });
  const [settings, setSettings] = useState(LS.getSettings());

  const [shouldReload, setShouldReload] = useState(false);

  const [phase, setPhase] = useState(0);
  const [max, setMax] = useState(0);
  const [count, setCount] = useState(0);

  const fileRef = useRef(null);

  const toggleLoadDataDialog = (isOpen: boolean) => {
    setDataLoadOpen(isOpen);
  }

  const updateSettings = () => {
    setSettings(LS.getSettings());
  }

  const updateAuthorCounts = async () => {
    const authors = await DB.getAllAuthors();

    setPhase(2);
    setCount(0);
    setMax(authors.length);

    return await Promise.all(authors.map(async (author) => {
      return DB.getPostsByAuthor(author.name).then(ret => {
        const counted = author;
        counted.count = ret.length;
        setCount(pre => pre + 1);
        return DB.updateData(tblName.Authors, counted.id, counted);
      })
    })).then(ret => {
      return ret.length
    }).catch(err => {
      console.log(err);
      return 0;
    })
  }

  /**
   * 20220609 更新できるように変更
   * @param doc Document
   */
  const createDB = async (data: ImportDataRow[]): Promise<boolean> => {

    setPhase(0);
    setMax(data.length);
    setCount(0);

    async function createArray(data: ImportDataRow[], parser: (value: ImportDataRow, index: number) => {post: Post, author: Author}) {
      const res: {posts: Post[], authors: Author[]} = {
        posts: [],
        authors: []
      };

      for (let i = 0; i < data.length; i++) {
        const ret = await parser(data[i], i);
        if (ret != null) {
          res.posts.push(ret.post);
          res.authors.push(ret.author);
        }
        setCount(pre => pre + 1);
        
      }
      return res;
    }

    const parsed = await createArray(data, dataParser);
    const authors: Author[] = setAuthorId(
      Array.from(
        new Map(parsed.authors.map(author => [author.name, author])).values()
      )
    )
  

    setPhase(1);
    setMax(parsed.posts.length + parsed.authors.length);
    setCount(0);

    return Promise.all([
      DB.addDataArray(tblName.Authors, authors).then(ret => {
        typeof ret === 'number' && setCount(pre => pre + ret);
        return ret;
      }),
      DB.addDataArray(tblName.Posts, parsed.posts).then(ret => {
        typeof ret === 'number' && setCount(pre => pre + ret);
        return ret;
      })
    ]).then(async _ => {
      return updateAuthorCounts().then(ret => {
        if (ret) {
          setPhase(3);
          return true;
        } else {
          setPhase(4);
          return false;
        }
      });
    }).catch(err => {
      setPhase(4);
      console.log(err);
      return false;
    });
  }


  /**
   * ファイルを読み込む
   */
  const loadFile = () => {
    const file = fileRef.current ? (fileRef.current as HTMLInputElement).files : null;

    if (file?.length) {


      const reader = new FileReader();

      reader.addEventListener('load', async () => {


        const posts: ImportDataRow[] = JSON.parse((reader.result as string)) as ImportDataRow[];
        console.log(posts);

        if (!posts) {
          alert(myEnv.strings.settings.formatNoMatch)
          return;
        }

        const siteData = await DB.getAllPosts();
        if (siteData.length) {
          if (window.confirm(myEnv.strings.settings.siteNoMatch)){
            await DB.deleteAllTables();
          } else {
            alert(myEnv.strings.settings.abort);
            return;
          }
        }

        toggleLoadDataDialog(true);

        createDB(posts).then(ret => {
          setSettings(pre => {
            return {
              lastFName: file[0].name,
              lastLoadDate: format(new Date(), 'yyyy/MM/dd hh:mm:ss', { locale: ja })
            } as Settings
          });

          setShouldReload(ret);
        });
      });

      reader.readAsText(file[0]);
    } else {
      alert(myEnv.strings.settings.noFileSelected);
    }

  }

  /**
   * データ削除ボタン
   */
  const tableClear = () => {
    if (window.confirm(myEnv.strings.settings.deleteData)) {
      LS.clean();
      return DB.deleteAllTables().then(_ => {
        updateSettings();
        return siteInfoUpdate();
      });
    }
  }


  /**
   * DB 作成完了ボタン
   */
  const dataLoadComplete = () => {
    siteInfoUpdate().then(() => {
      toggleLoadDataDialog(false);
    });
  }

  const siteInfoUpdate = () => {
    const counts = {
      Authors: 0,
      Posts: 0,
    };

    return Promise.all([
      DB.getDataCount(tblName.Authors).then(ret => counts.Authors = ret),
      DB.getDataCount(tblName.Posts).then(ret => counts.Posts = ret),
    ]).then(() => {
      setSiteInfo(counts);
    });
  };

  const dropDatabase = () => {
    LS.clean();
    DB.dropDB().then(_ => {
      updateSettings();
      setShouldReload(true);
    }).catch(err => {
      console.log(err);
    });
  }

  const settingFinish = () => {
    if (shouldReload) {
      navigate(0);
    } else {
      props.hideDialog();
    }
  }

  const update = () => {
    if (window.confirm(myEnv.strings.settings.version.doUpdate)) {
      window.navigator.serviceWorker.getRegistrations().then(registrations => {
        for (const registration of registrations) {
          registration.unregister();
        }
        window.location.reload();
      }).catch(err => {
        window.alert(`${myEnv.strings.settings.version.updateFailed}\n${err}`);
        window.location.reload();
      })
    }
  }

  const updateSiteName = (name: string) => {
    setSettings(pre => {
      return {
        siteName: name,
        lastFName: pre.lastFName,
        lastLoadDate: pre.lastLoadDate
      } as Settings
    });
  }


  useEffect(() => {
    setAppEl(document.getElementsByClassName('.App'));
    siteInfoUpdate();
  }, []);

  useEffect(() => {
    LS.setSettings(settings);
  }, [settings])


  return <ReactModal
    isOpen={props.show}
    ariaHideApp={false}
    appElement={appEl}
    portalClassName=""
    className="settings"
    style={{}}
    >
      <dialog open>
    <article>
      <header><h4>Settings</h4></header>
      <section>
        <h5>{myEnv.strings.settings.menu.version}</h5>
        <table role="grid">
          <tbody>
            <tr>
              <th>{myEnv.strings.settings.version.version}</th>
              <td>{myEnv.version}</td>
            </tr>
            <tr>
              <th></th>
              <td><button onClick={update}>{myEnv.strings.settings.buttons.update}</button></td>
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h5>{myEnv.strings.settings.menu.siteInfo}</h5>
        <table role="grid">
          <tbody>
            <tr>
              <th colSpan={2}>{myEnv.strings.settings.siteInfo.fname}</th></tr><tr>
              <td colSpan={2}>{settings.lastFName}</td>
            </tr>
            <tr>
              <th>{myEnv.strings.settings.siteInfo.date}</th>
              <td>{settings.lastLoadDate}</td>
            </tr>
            <tr>
              <th>{myEnv.strings.settings.siteInfo.Authors}</th>
              <td>{siteInfo.Authors} {myEnv.strings.settings.siteInfo.Count}</td>
            </tr>
            <tr>
              <th>{myEnv.strings.settings.siteInfo.Posts}</th>
              <td>{siteInfo.Posts} {myEnv.strings.settings.siteInfo.Count}</td>
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <label htmlFor="sitename">表示用サイト名</label>
        <input type="text" name="sitename" id="sitename" value={settings.siteName} onChange={ev => updateSiteName(ev.target.value)} />
      </section>
      <hr />
      <section>
        <h5>{myEnv.strings.settings.menu.create}</h5>
        {/* <p>ファイルを選択して読込ボタンを押してください。</p> */}
        <input type="file" name="file" id="file" accept="text/json,applcation/json" ref={fileRef} />
        <button onClick={loadFile}>{myEnv.strings.settings.buttons.readFile}</button>
      </section>
      <hr />
      <section>
        <h5>{myEnv.strings.settings.menu.deleteData}</h5>
        {/* <p></p> */}
        <table role="grid">
          <tbody>
            <tr>
              <th></th>
              <td>
                <button onClick={tableClear}>{myEnv.strings.settings.buttons.delete}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <hr />
      <section>
        <h5>{myEnv.strings.settings.menu.dropDB}</h5>
        {/* <p>DB を完全に削除します。</p> */}
        <table role="grid">
          <tbody>
            <tr>
              <th></th>
              <td>
                <button onClick={dropDatabase}>{myEnv.strings.settings.buttons.delete}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <footer>
        <button onClick={settingFinish}>{myEnv.strings.settings.buttons.end}</button>
      </footer>
    </article>

    <dialog open={dataLoadOpen} className="data-loading">
      <article>
        <header><h4>{(phase === 1 || phase === 2 || phase === 3 || phase === 4 || phase === 0) && myEnv.strings.settings.phase[phase]}</h4></header>
        <main>
          <progress max={max} value={count}></progress>
          {/* <div className="counter">{count} / {max}</div> */}
          <div className="counter">{phase >= 3 ? 100 : Math.floor(count / max * 100)}%</div>
        </main>
        <footer>
          <button disabled={phase < 3} onClick={dataLoadComplete}>{myEnv.strings.settings.buttons.end}</button>
        </footer>
      </article>
    </dialog>
    </dialog>
  </ReactModal>
}
export default SettingsDialog;