import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import DensListView from '../../components/dens-list-view/dens-list-view';
import NoDataFound from '../../components/no-data-found/no-data-found';
import { Author } from '../../libraries/db-types';
import DB from '../../services/storage-service';
import './terms.scss';

type Props = {
  active: boolean;
  updateSwitch: boolean;
}

const Terms = forwardRef<unknown, Props>((props, ref) => {

  const [items, setItems] = useState<Author[]>([]);
  const [filteredItems, setFilterdItems] = useState<Author[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [sortType, setSortType] = useState<string>('');

  useImperativeHandle(ref, () => ({
    setFilterWords: (tagFilter: string) => {
      setFilter(_ => tagFilter);
    },
    setSortType: (sort: string) => {
      setSortType(_ => sort);
    },
    }));

  useEffect(() => {
    DB.getAllAuthors().then(ret => {
      setItems(ret);
      setFilterdItems(ret);
    });
  }, [props.updateSwitch]);

  useEffect(() => {
    const filtered = items.filter(author => author.name.match(new RegExp(filter)));

    switch(sortType) {
      case "term_id":
        setFilterdItems(filtered.sort((a, b) => a.id - b.id));
        break;
      case "term_name":
        setFilterdItems(filtered.sort((a, b) => a.name < b.name ? -1 : 1));
        break;
      case "count":
        setFilterdItems(filtered.sort((a, b) => b.count - a.count));
        break;
      case "view":
        setFilterdItems(filtered.sort((a, b) => b.view - a.view));
        break;
      default:
        setFilterdItems(filtered.sort((a, b) => b.count - a.count));
        break;
    }
  }, [items, filter, sortType]);


  return <section id="terms" className={props.active ? 'active' : 'hidden'}>
    <div className="index"></div>
    <div className='container-fluid'>
      <DensListView items={filteredItems} type={`terms`} view={sortType === 'view'}><NoDataFound></NoDataFound></DensListView>
    </div>
  </section>;
})
export default Terms;