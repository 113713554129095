import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Post, tblName } from '../../libraries/db-types';
import { countFormat } from '../../libraries/utilities';
import DB from '../../services/storage-service';
import DateFormat from '../date-format/date-format';
import Icon from '../icons/icons';
import { LocationState } from '../page-stack';
import Spacer from '../spacer/spacer';
import './single-view.scss';

type Props = {
  item: Post,
  swipeBack:(state: boolean) => void;
}

const SingleView: React.FC<Props> = (props) => {

  const navigate = useNavigate();
  const [fav, setFav] = useState(!!props.item.fav);

  const listViewRequest = (name: string) => {
    DB.getAuthorByName(name).then(author => {
      if (author) {
        author.view += 1;
        DB.updateData(tblName.Authors, author.id, author);
      }
    });

    DB.getPostsByAuthor(name).then(ret => {
      ret.length && navigate('/list', { state: {
        title: `# ${name}`,
        items: ret,
        view: 'list'
      } as LocationState });
    });
  }

  const favBottonClick = () => {
    const updateItem = props.item;
    updateItem.fav = fav ? 0 : 1;
    DB.updateData(tblName.Posts, updateItem.id, updateItem).then(_ => {
      setFav(!!updateItem.fav);
    })
  }

  const onTouchHandler = (ev: React.TouchEvent<HTMLElement>) => {
    if (ev.type === "touchstart") {

      if (ev.touches.length === 1) {
        if (ev.touches[0].clientX <= 15) {
          props.swipeBack(true);
        }
      }
    } else {
      props.swipeBack(false);
    }
  }

  useEffect(() => {
    DB.getPost(props.item.id).then(ret => {
      setFav(!!ret.fav);
    });
  }, [props.item.id]);


  return <section className='single-view'
    onTouchStart={onTouchHandler}
    onTouchEnd={onTouchHandler}
  >
    {props.item.content.length ? <>
      <main dangerouslySetInnerHTML={{ __html: props.item.content }}></main>
      {/* <hr /> */}
      <footer>
        {/* {props.item.post_tag.length > 0 && <hr />}
        <TagChips tags={props.item.post_tag} onTagClick={listViewRequest}></TagChips>
        <hr />
        {props.item.category.length !== 0 && <><div className='categories'>
          {props.item.category.map((category, index) => {
            return <span key={index} onClick={() => listViewRequest(category)}>
              <i className='icon-folder'></i><small>{category}</small>
            </span>
            })
          }
          </div><hr /></>} */}
        <div className='categories'>
          <span onClick={() => listViewRequest(props.item.author)}>
            <i className='icon-folder'></i><small>{props.item.author}</small>
          </span>
        </div>
        <hr />
        <div className='single-meta'>
          <span>
            <i className='icon-date'></i><small>
              <DateFormat date={props.item.date}></DateFormat>
            </small>
          </span>
          <span>
            <i><Icon name='views' onClick={() => {}}></Icon></i><small>
              {countFormat(props.item.view)}
            </small>
          </span>
          <Spacer></Spacer>
          <div className='actions'>
            <Icon name='favorite' onClick={favBottonClick} active={fav}></Icon>
          </div>
        </div>
      </footer>
    </>
      :
      <main>Loading...</main>}
  </section>
}
export default SingleView;