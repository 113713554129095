import React, { forwardRef, ReactNode, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactVirtualizedAutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { Post, tblName } from '../../libraries/db-types';
import DB from '../../services/storage-service';
import Card from '../card/card';
import { LocationState } from '../page-stack';
import './list-view.scss';

type Props = {
  items: Post[],
  children?: ReactNode,
}


const ListView = forwardRef<unknown, Props>((props, ref) => {

  useImperativeHandle(ref, () => ({
    scrollToTop: () => listRef.current?.scrollTo(0)
  }));

  const navigate = useNavigate();
  const listRef = React.createRef<FixedSizeList<any>>();

  const onCardClick = (index: number) => {
    DB.getPost(props.items[index].id).then(data => {
      data.view += 1;
      props.items[index].view += 1;
      DB.updateData(tblName.Posts, data.id, data).then(_ => {

        navigate('/article', { state: {
          title: data.title,
          items: props.items.slice(index),
          view: 'single'
        } as LocationState});
      });
    })
  }


  return <>
  { props.items.length > 0?
  <ReactVirtualizedAutoSizer>
    {({ height, width }: Size) => {
      return <FixedSizeList
        ref={listRef}
        height={height}
        width={width}
        itemCount={props.items.length}
        itemSize={272}
      >
        {(p) => Card({ ...p, item:props.items[p.index], onClickHandler: onCardClick })}
      </FixedSizeList>
    }}
  </ReactVirtualizedAutoSizer>
  :
    <div>{props.children}</div>
  }
  </>
})
export default ListView;
