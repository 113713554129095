import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import List from "../layouts/list/list";
import Single from "../layouts/single/single";
import { Post } from "../libraries/db-types";

type Props = {
}

export type LocationState = {
  view: 'single' | 'list',
  title?: string,
  index?: number,
  items: Post[],
}

const PageStack: React.FC<Props> = (props) => {
  const location = useLocation();
  const navigate = useNavigate()
  const navgationType = useNavigationType();
  const [stack, setStack] = useState<any[]>([]);

  const onBackButtonClick = useCallback(() => {
    const stacks = document.querySelectorAll(".App > .page");
    const targetPage = stacks[stacks.length - 1];
    targetPage.classList.add('slide-out');
    setTimeout(() => {
      navigate(-1);
    }, 200);
  }, [navigate]);
  
  useEffect(() => {
    
    const setStackItem = () => {
      switch ((location.state as LocationState).view) {
        case 'single':
          setStack(pre => [...pre, <Single
            data={(location.state as LocationState)}
            onBackButtonClick={onBackButtonClick}></Single>]);
          break;
        case 'list':
          setStack(pre => [...pre, <List
            data={(location.state as LocationState)}
            onBackButtonClick={onBackButtonClick}></List>]);
          break;
        default:
          break;
      }
    }
    if (location.pathname === '/') {
      setStack(pre => []);
    } else {

      if (navgationType === 'PUSH') {
        if (location.state) {
          setStackItem();
        }
      } else if (navgationType === 'POP') {
        setStack(pre => pre.length > 0 ? pre.slice(0, -1) : pre);
      }
    }
  }, [location, navgationType, onBackButtonClick])

  return <>
    {stack.map((num, index) => <div className="page" key={index}>{num}</div>)}
  </>

}
export default PageStack;