import { useEffect, useState } from 'react';
import ListView from '../../components/list-view/list-view';
import NoDataFound from '../../components/no-data-found/no-data-found';
import { Post } from '../../libraries/db-types';
import { myEnv } from '../../libraries/_env';
import DB from '../../services/storage-service';
import './home.scss';

type Props = {
  active: boolean;
  updateSwitch: boolean;
  onTop: boolean;
}


const Home: React.FC<Props> = (props) => {

  const [posts, setPosts] = useState<Post[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    DB.getAllPosts().then(ret => {
      setPosts(ret);
      return;
    }).then(() => {
      setLoaded(true);
    })
  }, [props.updateSwitch, props.active, props.onTop]);

  return <section id='home' className={props.active ? 'active' : 'hidden'}>
    <div className="index"></div>
    <div className='container-fluid'>
      {loaded ? 
      <ListView items={posts}><NoDataFound>{myEnv.strings.noDataFound}</NoDataFound></ListView>
      :
      <div aria-busy></div> }
    </div>
  </section>;
}
export default Home;