import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { myEnv } from '../../libraries/_env';
import SettingsDialog from '../../templates/settings-dialog/settings-dialog';
import Icon, { IconName } from '../icons/icons';
import Spacer from '../spacer/spacer';
import './top-app-bar.scss';

type Props = {
  title?: string,
  active?: IconName;
  backIcon?: boolean;
  onBackButtonClick?: (action: string) => void;
  settingIcon?: boolean;
  onSettingEnd?: () => void;
  onSearchRequest?: (words: string) => void,
  onFilterChange?: (tagFilter: string) => void,
  onSelectChange?: (sortType: string) => void,
}

const TopAppBar = forwardRef<unknown, Props>((props, ref) => {

  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [words, setWords] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    setSearchWords: (str: string) => {
      setWords(str);
      props.onSearchRequest && props.onSearchRequest(str);
    },
    focusRequest: () => {
      inputRef.current?.focus();
    }
  }));

  const onBackButtonClickHandler = (action: string) => {
    props.onBackButtonClick && props.onBackButtonClick(action);
  }

  const toggleSettings = (show: boolean) => {
    setSettingsOpen(show);
  }

  const settingEnd = () => {
    toggleSettings(false);
    props.onSettingEnd && props.onSettingEnd();
  }

  const getTitle = () => {
    switch (props.active) {
      case 'home':
        return props.title;
      case 'article':
        return myEnv.title.article;
      case 'photos':
        return myEnv.title.gallery;
      case 'search':
        return myEnv.title.search;
      case 'bookmarks':
        return myEnv.title.bookmorks;
      case 'favorite':
        return myEnv.title.favorite;
      default:
        return props.title
    }
  }

  const searchCancel = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    setWords('');
    props.onSearchRequest && props.onSearchRequest('');
    inputRef.current?.focus();
  }

  const searchRequest = (/*ev: React.FormEvent<HTMLFormElement>*/) => {
    inputRef.current?.blur();
    props.onSearchRequest && props.onSearchRequest(words);
  }

  const onFilterChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    props.onFilterChange && props.onFilterChange(ev.target.value);
  }

  const onSelectChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    props.onSelectChange && props.onSelectChange(ev.target.value);
  }

  useEffect(() => {
    if (props.active === 'search') {
      (inputRef.current as HTMLInputElement).focus();
    }
  }, [props.active]);


  return <>
    <header className="app-bar">
      {props.backIcon && <>
        <Spacer></Spacer>
        <Icon name='arrow' onClick={() => onBackButtonClickHandler('goBack')}></Icon>
      </>}
      {/* タイトル */}
      {props.active !== 'search' ?
        <span className={`title top`} dangerouslySetInnerHTML={{ __html: getTitle() || '' }}></span>
        :
        <>
          <form id='search-form' onSubmit={ev => { ev.preventDefault(); searchRequest() }}>
            <input ref={inputRef} type="search" name="search" id="search-word" value={words} onChange={e => setWords(e.target.value)} />
            {/* <Spacer></Spacer> */}
            <button type='reset' className={`outline cancel-button${words.length ? ' canceable' : ''}`} onClick={ev => { searchCancel(ev) }}>×</button>
          </form>
        </>
      }
      {/* タグ一覧ページ */}
      {props.active === 'bookmarks' && <>
        <Spacer></Spacer>
        <input type="text" name="filter" id="tag-filter" onChange={ev => { onFilterChange(ev) }} />
        {/* <button type="button" name="sort" id="sort-button">↑↓</button> */}
        <select id="tag-sort" onChange={ev => onSelectChange(ev)} defaultValue="term_count">
          <option value="term_id" disabled>ソート</option>
          <option value="term_id">ID</option>
          <option value="term_name">名前</option>
          <option value="count">件数</option>
          <option value="view">閲覧数</option>
        </select>
      </>}
      {/* {props.active !== 'bookmarks' && props.active !== 'search' &&<>
      <Spacer></Spacer>
      <select className="sort" onChange={ev => onSelectChange(ev)} defaultValue="date">
        <option value="date" disabled>ソート</option>
        <option value="id">ID</option>
        <option value="name">名前</option>
        <option value="date">日付</option>
        <option value="view">閲覧数</option>
      </select>
      </>} */}
      {props.settingIcon && <>
        <Spacer></Spacer>
        {/* <Icon name='settings' onClick={() => toggleSettings(true)}></Icon> */}
        <Icon name='settings' onClick={() => toggleSettings(true)}></Icon>
      </>}
    </header>
    <SettingsDialog show={settingsOpen} hideDialog={settingEnd}></SettingsDialog>
  </>
})
export default TopAppBar;