import { myEnv } from "../libraries/_env";

export const LSKeys = {
  history: 'history',
  settings: 'settings'
} as const;
export type SearchHistory = {
  words: string,
  count: number,
}
export type Settings = {
  siteName: string,
  lastFName: string,
  lastLoadDate: string,
}

let LocalStorageSeviceInstance: LocalStorageSevice;

class LocalStorageSevice {
  constructor() {
    if (LocalStorageSeviceInstance) {
      throw new Error('Local Storage Service can be only created one instance.');
    }
    LocalStorageSeviceInstance = this;
  }


  private setSearchHistory(value: SearchHistory[]) {
    localStorage.setItem(LSKeys.history, JSON.stringify(value));
  }

  private getSearchHistory() {
    return JSON.parse(localStorage.getItem(LSKeys.history) || '[]') as SearchHistory[];
  }

  private removeSearchHistory() {
    localStorage.removeItem(LSKeys.history);
  }

  /**
   * 検索履歴の取得
   */
  getHistory() {
    return this.getSearchHistory();
  }

  /**
   * 検索履歴の追加
   * @param name 
   * @param count 
   */
  addHistory(words: string, count: number) {
    const now = this.getSearchHistory();
    now.unshift({words, count});
    const added = now.filter((v, i, array) => {
      return array.findIndex((test) => {
        return test.words.toLowerCase() === v.words.toLowerCase();
      }) === i;
    }).slice(0, myEnv.searchHistoryLength);
    this.setSearchHistory(added);
    return added;
  }


  /**
   * 検索履歴を置換
   * @param data SearchHistory
   */
  replaceHistory(data: SearchHistory[]) {
    this.setSearchHistory(data);
  }


  /**
   * 検索履歴を1件削除
   * @param word 削除するワード
   */
   removeHistory(data: SearchHistory) {
    const filtered = this.getHistory().filter(log => {
      return log.words !== data.words
    });
    // setSearchLog(filtered);
    this.replaceHistory(filtered);
    // console.log('removed', filtered);
    return filtered;
  }
  


  getSettings() {
    return JSON.parse(localStorage.getItem(LSKeys.settings) || `{
      "siteName": "",
      "lastFName": "",
      "lastLoadDate": ""
    }`) as Settings;
  }

  setSettings(value: Settings) {
    localStorage.setItem(LSKeys.settings, JSON.stringify(value));
  }

  getSiteName() {
    return this.getSettings().siteName;
  }

  /**
   * すべて削除
   */
  clean() {
    localStorage.clear();
    // this.removeSearchHistory();
  }
}

const LS = Object.freeze(new LocalStorageSevice());
export default LS;