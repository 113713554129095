import PageStack from "../components/page-stack";
import Content from "./content";


type Props = {
}

const Main: React.FC<Props> = (props) => {








  return (
    <>
      {/* <Routes>
        <Route path="/" element={<> */}
      {/* <TopAppBar
        title={props.appData.title}
        settingIcon
        onSettingEnd={onUpdateRequest}></TopAppBar> */}
          {/* <Content appEl={appEl} active={activeMenu} appData={props.appData} updateSwitch={updateSwitch}></Content> */}
          <Content></Content>
          {/* <NavigationBar active={activeMenu} onActiveChange={onActiveChange}></NavigationBar> */}
            {/* <Outlet></Outlet> */}
        {/* </>}> */}
        {/* <Route path="/article" element={
          <Single></Single>}></Route>
        <Route path="/list" element={
          <List></List>}></Route> */}
        {/* </Route>
      </Routes> */}
      <PageStack></PageStack>
    </>
  );
}
export default Main;